@import "bootstrap";

.who-we-are {
  margin-top: 12rem;
}

.who-we-are-container {
  @include media-breakpoint-up(lg) {
    margin-left: 6rem;
  }
}

// team
.about-our-team {
  @extend .who-we-are-container;

  margin-top: 7.5rem;
  margin-bottom: 6rem;
}
