@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "./variable.scss";

* {
  -webkit-font-smoothing: antialiased; // làm mịn font nha
  -moz-osx-font-smoothing: grayscale; // khử răng cưa theo thang độ xám
  text-rendering: optimizeLegibility; // nó tăng hiệu xuất làm dễ đọc hơn
  box-sizing: border-box; // w/height không tăng khi pd/mr được tăng
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  color: $text;
}

img {
  max-width: 100%;
}
.App {
  position: relative;
  min-height: 100vh;
}

.height-6 {
  height: $font-size-base;
}
.height-5 {
  height: $font-size-base * 1.25;
}
.height-4 {
  height: $font-size-base * 1.5;
}
.height-3 {
  height: $font-size-base * 1.75;
}
.height-2 {
  height: $font-size-base * 2;
}
.height-1 {
  height: $font-size-base * 2.5;
}

.btn-link {
  color: $brand-1;
  text-decoration: none;
  :hover,
  &:active,
  &:focus {
    color: $white;
    box-shadow: none;
  }
}

.btn-outline-primary {
  color: $brand-1;
  border-color: $brand-1;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: $brand-1;
    border-color: $brand-1;
    box-shadow: none;
  }
}

.bg-gradient-white {
  background: $gradient-white;
}

.bg-hero {
  background-image: url("../images/bg-home.svg");
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

.bg-hero-2 {
  background-image: url("../images/bg-2.svg");
  background-position: bottom right;
  background-size: 70%;
  background-repeat: no-repeat;
}

.bg-hero-3 {
  background-image: url("../images/bg-3.svg");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

:focus {
  outline: none !important;
}

*::before *::after {
  box-sizing: border-box;
}

header {
  background-color: transparent;
  z-index: 1;
  transition: 0.5s;
}

footer {
  text-align: center;
  z-index: 1;
  position: relative;
}

body {
  background-color: $background;
  color: $text;
  font-weight: normal;
  font-family: "Saira";
  font-size: 1.1rem;
}

a {
  color: $brand-1;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

strong {
  font-weight: 600;
}

.bg-default {
  background-color: $background;
}

.text-brand {
  color: $brand-1;
}

.text-brand-2 {
  color: $brand-2;
}
// .text-brand-3 {
//   color: $brand-3;
// }
// .text-brand-4 {
//   color: $brand-4;
// }
// .text-brand-5 {
//   color: $brand-5;
// }
// .text-brand-6 {
//   color: $brand-5;
// }

.modal-content {
  background: $background;
  background-size: cover;
  border: none;
}

.bg-brand {
  background-color: $brand-2;
}

.bg-light {
  background-color: $light;
}

.modal-header {
  border-bottom: 0px;
}

.modal-footer {
  border-top: 0px;
}

.number {
  font-family: "Fira Code", monospace;
  letter-spacing: -0.02em;
}

.monospace {
  font-family: "Fira Code", monospace;
}

.logo-icon {
  height: 1.5em;
}

.btn {
  /* transform: scale(1); */
  transition: all 0.5s;
}

.btn:hover {
  transition: all 0.5s;
  /* transform: scale(.95); */
}

.btn:active {
  transition: all 0.5s;
  /* transform: scale(.95); */
}

.btn:focus {
  transition: all 0.5s;
  /* transform: scale(.95); */
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3;
}

.nav-link {
  color: $secondary;
}

.nav-link.active,
.nav-link:hover {
  color: $text;
}
.nav-link:focus {
  color: inherit;
}

.card {
  border-radius: 1rem;
  border: 1px solid rgba(42, 122, 215, 0.15);

  transition: all 0.2s;
  // box-shadow: 0 5px 5px -10px rgba(0, 0, 0, 0.25);

  &:hover {
    // box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(42, 122, 215, 0.5);
  }
}
.card-cta {
  background-color: $brand-1;
  border: 1px solid $brand-1;
  .card-body {
    h5,
    .lead {
      color: white !important;
    }
  }
}

.card-footer {
  background-color: transparent;
  border-top: none;
}

.nav-link {
  cursor: pointer;
}

.nav-pills .nav-link:hover {
  transition: all 0.5s;
  /* box-shadow: var(--box-shadow-inset-sm); */
  border-radius: 10rem;
}
.nav-pills .nav-link.active {
  background: transparent;
  /* box-shadow: var(--box-shadow-inset); */
  border-radius: 10rem;
}

.navbar {
  padding: 1.2rem 1rem;
}

.token-icon {
  height: 4em;
}

.token-1em {
  height: 1em;
}

/* INLINE LOADER */

.loader {
  height: 8px;
  width: 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */
}

.loader-box {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0px 2px;
  background-color: $brand-1;
  animation-name: fadeOutIn;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loader-box:nth-child(1) {
  animation-delay: 250ms;
} /* (1/2) * <animation-duration: 500ms */
.loader-box:nth-child(2) {
  animation-delay: 500ms;
} /* (2/2) * <animation-duration: 500ms */
.loader-box:nth-child(3) {
  animation-delay: 750ms;
} /* (3/2) * <animation-duration: 500ms */

@keyframes fadeOutIn {
  0% {
    background-color: $brand-1;
  }
  100% {
    background-color: #fff;
  }
}

.img-inline {
  height: 1.3em;
}

.brand-shadow {
  color: rgba(255, 255, 255, 0.96);
  text-shadow: 0 0 80px rgba(192, 219, 255, 0.5),
    0 0 32px rgba(65, 120, 255, 0.25);
}
.hero-img {
  width: 70%;
}
.parallax-effect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  color: white;
  border-radius: 50px;
  transform-style: preserve-3d;

  // &:hover {
  // background-color: $background;
  // }

  .inner-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateZ(60px);

    &:hover {
      color: $text !important;
      text-shadow: 0 0 20px rgba(192, 219, 255, 0.5);
      background-image: none;
    }
  }
}

@media (min-width: 768px) {
  .card-stack {
    display: grid;
    grid-auto-rows: 1fr;
  }

  .card-stack-item,
  .sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
  }
  .card-stack-item > .card {
    transition: all 0s;
    // background-color: #13202f;
    // -webkit-backdrop-filter: blur(20px) !important;
    // backdrop-filter: blur(20px) !important;

    // -webkit-backdrop-filter: none !important;
    // backdrop-filter: none !important;
    height: 100%;
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    margin-bottom: 5em;
  }
  .card-stack-item > .card > .row {
    height: inherit;
  }
}

.div-highlight {
  height: 1px;
  // box-shadow: 0px 0px 10px $brand-50;
  background-image: linear-gradient(
    45deg,
    #c9b1ff,
    #ffcaf2,
    #ffb2b1,
    #fff3ad,
    #bcffbc,
    #a2edff
  );
}

.bg-video {
  mix-blend-mode: screen;
  position: fixed;
  top: 0;
  right: 0;
}

.list-group-item {
  background: transparent;
  color: inherit;
}

.bg-none {
  background-color: transparent;
  box-shadow: none;
}

.bg-dark {
  background-color: $text;
  color: white;
}

.logo-img {
  max-width: 40px;
  max-height: 40px;
}

.ocean {
  width: 100%;
  bottom: 0;
  left: 0;
}

.wave {
  background: url("../images/wave.png") repeat-x;
  // width: 6400px;
  height: 50px;
  animation: wave 15s infinite;
  // transform: translate3d(0, 0, 0);
  background-position: top;
}

.wave-up {
  background: url("../images/wave-up.png") repeat-x;
  // width: 6400px;
  height: 50px;
  animation: wave 22s infinite;
  // transform: translate3d(0, 0, 0);
  background-position: bottom;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -6400px;
  }
}

.banner-buttons-container {
  &:hover {
    background: $brand-2;
  }
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(8px);
  border-radius: 62px;
  padding: 11px 10px 11px 22px;
  transition: all 0.2s;
  .link-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .banner-buttons-col {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons-icon-field {
      background: #fff;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .buttons-content-field {
      h5 {
        font-size: 18px;
        line-height: 21px;
        color: #fff;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        margin-bottom: 0;
        // white-space: nowrap;
      }
    }
  }
}

.lead {
  font-size: 1.5rem;
  color: $text;
  // font-weight: 300;
}

.game-icon {
  max-width: 350px;
}
.feature-icon {
  max-width: 200px;
}

.dev-icon {
  max-width: 100px;
}

.our-team-icon {
  max-width: 150px;
}

.btn-primary {
  color: white;
  background-color: $brand-1;
  &:hover {
    color: white;
    background-color: $brand-1;
  }
}

.btn-cta {
  border-radius: 0 !important;
  padding: 0.5rem 2rem;
  color: white;
  background-color: $brand-1;
  &:hover {
    color: white;
    background-color: $brand-2;
  }
}

.btn-cta-secondary {
  border-radius: 50rem !important;
  padding: 1rem 2rem;
  color: $brand-2;
  background-color: transparent;
  border: solid $brand-2;
  &:hover {
    background-color: $brand-1;
  }
}

.team-avatar {
  width: 160px;
  max-width: 80%;
}

.brand-token-icon {
  max-width: 200px;
}
.icon-container {
  height: 80px;
  padding: 10px 0;
  .icon-img {
    height: 50px;
    max-width: 80%;
    width: auto;
  }
}

.dropdown-menu {
  padding: 0;
  border-radius: 0;
  border: none;
  border-top: 2px solid $brand-1;
  .dropdown-item {
    color: $brand-1;
    font-weight: normal;
    padding: 0.5rem 1.2rem;
    &:hover {
      background-color: $brand-2;
    }
  }
}

.block {
  max-width: 100%;
  // margin-top: -300px;
}

.hamburger {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 45px;
  padding: 0.6rem;

  /* FOR DISPLAYING EVERY ELEMENT IN THE CENTER : */

  display: flex;
  align-items: center;
  justify-content: center;

  /* CREATING THE MIDDLE LINE OF THE HAMBURGER : */

  div {
    position: relative;
    top: 0;
    left: 0;
    background: $text;
    height: 2px;
    width: 100%;
    transition: all 0.4s ease;

    /* CREATING THE TOP AND BOTTOM LINES : 
TOP AT -10PX ABOVE THE MIDDLE ONE AND BOTTOM ONE IS 10PX BELOW THE MIDDLE: */

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      background: $text;
      width: 100%;
      height: 2px;
      transition: all 0.4s ease;
    }

    &::after {
      top: 10px;
    }
  }
}

.toggler {
  /* ALWAYS KEEPING THE TOGGLER OR THE CHECKBOX ON TOP OF EVERYTHING :  */
  z-index: 2;
  height: 40px;
  width: 45px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  opacity: 0;
}
/* IF THE TOGGLER IS IN ITS CHECKED STATE, THEN SETTING THE BACKGROUND OF THE MIDDLE LAYER TO COMPLETE BLACK AND OPAQUE :  */

.toggler:checked + .hamburger > div {
  background: rgba(0, 0, 0, 0);
}

.toggler:checked + .hamburger > div::before {
  top: 0;
  transform: rotate(45deg);
  background: black;
}

/* AND ROTATING THE TOP AND BOTTOM LINES :  */

.toggler:checked + .hamburger > div::after {
  top: 0;
  transform: rotate(135deg);
  background: black;
}

/* MAIN MENU WITH THE WHITE BACKGROUND AND THE TEXT :  */

.menu {
  background: white;
  width: 0%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  /* APPLYING TRANSITION TO THE MENU :  */

  transition: all 0.4s ease;
  right: 0;
}

/* IF THE TOGGLER IS CHECKED, THEN INCREASE THE WIDTH OF THE MENU TO 30% , CREATING A SMOOTH EFFECT :  */

.toggler:checked ~ .menu {
  width: 200px;
}

/* STYLING THE LIST :  */

.menu > div > ul {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  padding-left: 10px;

  /* HIDDEN INITIALLY  :  */

  visibility: hidden;
}

.menu > div > ul > li {
  list-style: none;
}

.menu > div > ul > li > a {
  text-decoration: none;
}

/* WHEN THE TOGGLER IS CHECKED, CHANGE THE VISIBILITY TO VISIBLE :  */

.toggler:checked ~ .menu > div > ul {
  transition: visibility 0.4s ease;
  transition-delay: 0.1s;
  visibility: visible;
}

.btn-arrow {
  color: white;
  padding: 0.5em 1em;
  &:hover {
    .arrow {
      transform: translateX(10%);
    }
  }
  .arrow {
    transition: 0.3s all;
    margin-left: 1em;
  }
}

.btn-arrow-left {
  color: white;
  padding: 0.5em 1em;
  &:hover {
    .arrow {
      margin-right: 1.5em;
    }
  }
  .arrow {
    transition: 0.3s all;
    margin-right: 1em;
  }
}

.btn-outline-white,
.btn-outline-white:hover {
  color: white;
  border-color: white;
}

.hr-line {
  height: 1px;
  width: 100%;
  background: linear-gradient(to right, #ffffff00 50%, #000000 100%);
}

.hr-line-rev {
  height: 1px;
  width: 100%;
  background: linear-gradient(to left, #ffffff00 50%, #000000 100%);
}

.header-background {
  width: 100%;
  background-size: cover;
  background-repeat: none;
  margin-bottom: -120px;
}

.tech-footer-bg {
  background-image: url("../images/tech-ft-bg.svg");
  background-position: right center;
  background-repeat: no-repeat;
}

.bg-black {
  background-color: #000000;
  color: #fff;
}

.text-gradient {
  background: linear-gradient(90deg, #222222 0%, #2a7ad7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bg-about-gradient {
  background: linear-gradient(
    90deg,
    rgba(42, 122, 215, 0.3) 0%,
    #2a7ad7 61.57%,
    #0a285d 137.19%
  );
}

.muto-img {
  margin-top: -100px;
}

.team-img-container:hover {
  .team-linkedin {
    z-index: 1;
  }

  .team-img {
    scale: 1.1;
    filter: grayscale(100%);
  }
}

.team-img {
  width: 180px;
  max-width: 100%;
  filter: grayscale(100%);
  transition: all ease-in-out 0.5s;
}

.team-linkedin {
  cursor: pointer;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 0;
  right: 0;
}

.animation-height {
  height: 1040px;
}

@include media-breakpoint-down(lg) {
  .animation-height {
    height: 800px;
  }
}

@include media-breakpoint-down(md) {
  .animation-height {
    height: 540px;
  }
}

.incubate-bg {
  background-color: $incubate-bg;
}

.divider-mx {
  width: calc(100% - var(--bs-gutter-x));
  margin-left: calc(var(--bs-gutter-x) / 2);
  margin-right: calc(var(--bs-gutter-x) / 2);
}

.he-logo {
  font-weight: 500;
  font-size: 1.75rem;
  margin-bottom: 0;
  color: #000000;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 1024px !important;
  height: 576px !important;
}

/* For all pages */
.all-page-container {
  position: relative;
  height: 100%;
  max-height: 576px;
  overflow: hidden;

  &:hover {
    .btn-download-pdf {
      z-index: 5;
      color: white;
      display: block;
    }
  }
}

.btn-download-pdf {
  position: absolute;
  display: none;
  background-color: $brand-1;
  color: white;
  top: 16px;
  right: 16px;
}

.explorer-bg-gradient {
  background: linear-gradient(
    90deg,
    rgba(42, 122, 215, 0.03) 0%,
    rgba(42, 122, 215, 0) 100%
  );
}

.contact-us-bg {
  position: absolute;
  bottom: 0;
}

.mb-form {
  margin-bottom: 2.5rem;
}

.mb-field {
  margin-bottom: 2rem;
}

.text-gradient {
  color: linear-gradient(90deg, #0e336b 0%, #2a7ad7 100%);
}

.link-arrow {
  &:hover {
    .arrow {
      margin-left: 1.5em;
    }
    .arrow-left {
      transform: translateX(10%);
    }
    .arrow-right {
      transform: translateX(-10%);
    }
  }
  .arrow {
    transition: 0.3s all;
    margin-left: 1em;
  }
  .arrow-right {
    transition: 0.3s all;
  }
  .arrow-left {
    transition: 0.3s all;
    margin-right: 1em;
  }
}

.divider-gradient-pseudo {
  &:before {
    content: "";
    position: absolute;
    width: 3.0625rem;
    height: 0.25rem;
    background: linear-gradient(90deg, #0e336b 0%, #2a7ad7 100%);
    bottom: 0;
  }
}

.divider-gradient {
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90deg,
    rgba(42, 122, 215, 0) 19.1%,
    #2a7ad7 51.24%,
    rgba(42, 122, 215, 0) 81.43%
  );
}

.card-product {
  min-width: 512px;
}

.products-container {
  overflow-x: scroll;
  gap: 1rem;
}

.product-section {
  border-bottom: 1px solid #e5e5e5;
  // padding: 4rem 0;

  @include media-breakpoint-up(md) {
    // padding: 8rem 0;
  }
}
