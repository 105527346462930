@import "./variable.scss";

.gap-1 {
  height: 30px;
}

.gap-2 {
  height: 40px;
}

.gap-3 {
  height: 60px;
}

.gap-4 {
  height: 80px;
}

.gap-5 {
  height: 100px;
}

.gap-6 {
  height: 120px;
}

.gap-7 {
  height: 140px;
}

.text-xs {
  font-size: 0.6em;
}

.text-sm {
  font-size: 0.8em;
}

.text-2 {
  font-size: 1.5em;
}
.text-3 {
  font-size: 2em;
}
.text-4 {
  font-size: 2.5em;
}
.text-5 {
  font-size: 3em;
}
.text-6 {
  font-size: 4em;
}

.text {
  &-body-2 {
    font-weight: 400;
    font-size: 1.25rem;
  }
}

.heading {
  &-4 {
    font-size: 2rem;
    font-weight: 600;
    color: $brand-1;
  }
  &-g2 {
    background: $gradient-g2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }
}
