@import "bootstrap";

.workshop-student {
  order: -1;

  h1 {
    margin-top: 3.5rem;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    h1 {
      margin-top: 2rem;
      margin-bottom: 1.5rem;

      flex-direction: column;

      p {
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;
      }
    }
  }
}

.workshop-giftbox {
  display: flex;
  align-items: center;

  gap: 0.5rem;
  text-align: center;

  @include media-breakpoint-down(md) {
    align-items: flex-start;
  }
}

.workshop-input {
  display: flex;
  gap: 0.75rem;
  height: 3rem;
  margin-top: 2.125rem;

  button {
    border-radius: 0.75rem;
    padding: 0.5rem 1.5rem;
  }

  input {
    border-radius: 0.75rem;
    border: 0.09375rem solid #2a7ad7;
    background: white;

    padding: 0.5rem 1.25rem;
    width: 24rem;

    &::placeholder {
      color: #6c6c6c;
    }
  }

  @include media-breakpoint-down(md) {
    margin: 1.5rem 0;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

    input {
      width: 100%;
    }
  }
}

.workshop-note {
  display: flex;
  border: 0.0625rem solid #dadada;
  border-radius: 0.75rem;

  background: white;
  gap: 2.5rem;
  margin-top: 5rem;

  margin-bottom: 0;
  width: fit-content;
  padding: 0.5rem 1rem;

  span {
    background-color: #2a7ad7;
    border-radius: 100%;

    font-weight: medium;
    color: white;
    margin-right: 0.5rem;

    text-align: center;
    width: 1.625rem;
    height: 1.625rem;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;

    order: -1;
  }
}

.workshop-banner {
  margin: 2.25rem 0 1.5rem 0;
  display: flex;
  gap: 1.5rem;

  img {
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.workshop-thank {
  text-align: center;

  margin-bottom: 1.5rem;
  font-size: 14px;
  font-weight: 500;

  color: #474747;
}
