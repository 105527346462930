$secondary: #474747;
$background: #ffffff;
$text: #222222;
$light: #fafafa;

$brand-1: #2a7ad7;
$brand-2: #0e336b;
// $brand-3: #389ae1;
// $brand-4: #3ea9e6;
// $brand-5: #45b9eb;
// $brand-6: #4cc9f0;
$incubate-bg: #edf5ff;
$theme-colors: (
  "secondary": $secondary,
);

// gradient
$gradient-white: linear-gradient(
  180deg,
  rgba(250, 250, 250, 0) 17.41%,
  rgba(42, 122, 215, 0.03) 100%
);

$gradient-g2: linear-gradient(90deg, #0e336b 0%, #2a7ad7 100%);
