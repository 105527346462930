@import "bootstrap";

.product {
  &-list-prject {
    padding-top: 10rem;

    @include media-breakpoint-up(lg) {
      padding-top: 13rem;
    }
  }

  &-section {
    padding: 3rem 0;

    @include media-breakpoint-up(lg) {
      padding: 5rem 0;
    }
  }
}
