@font-face {
  font-family: 'Saira';
  src: url('../font/Saira-Regular.woff2') format('woff2'),
    url('../font/Saira-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Saira';
  src: url('../font/Saira-Medium.woff2') format('woff2'),
    url('../font/Saira-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Saira';
  src: url('../font/Saira-Bold.woff2') format('woff2'),
    url('../font/Saira-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Saira';
  src: url('../font/Saira-Light.woff2') format('woff2'),
    url('../font/Saira-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Saira';
  src: url('../font/Saira-SemiBold.woff2') format('woff2'),
    url('../font/Saira-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}