.contact-label {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.form-control {
  padding: 0.75rem 1rem !important;

  &:focus {
    &:not(.form-control.is-invalid) {
      box-shadow: none !important;
      border: 1px solid #2a7ad7 !important;
      box-shadow: 0px 0px 0.625rem rgba(42, 122, 215, 0.2) !important;
    }
  }
}

.form-control.is-invalid {
  background-image: unset !important;

  &:focus {
    box-shadow: 0px 0px 0.625rem rgba(244, 67, 54, 0.2) !important;
  }
}
