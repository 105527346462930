@import "bootstrap";

.footer-container {
  display: grid;
  padding: 4.5rem 0 3.625rem 0;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer-padding {
  padding: 0.75rem 2.8125rem;
}

.footer-signup {
  input {
    @extend .footer-padding;
    font-size: 1rem;
    font-weight: 400;
    color: white !important;

    border: 1px solid transparent;
    border-radius: 0.5rem;

    transition: 0.5s all;
    background: rgb(71, 71, 71);

    &:focus {
      background: rgba(71, 71, 71, 0.5);
    }
  }

  .input-group-text {
    border: 1px solid transparent;
  }
}

.footer-media {
  color: #b5b5b5;
  font-weight: 300;
  transition: 0.05s linear;

  &:hover {
    font-weight: 400;
    color: white;
    transition: 0.05s linear;
  }
}
