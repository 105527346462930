@import "node_modules/bootstrap/scss/bootstrap.scss";

// blockchain
.home-blockchain-solution {
  padding-top: 8rem;
  padding-bottom: 13rem;
}

// web3
.home-from-web3 {
  margin: 5.5rem 0 10.3125rem 0;

  @include media-breakpoint-up(lg) {
    margin-top: 13.5rem;
  }
}

// Layer
.home-mutiple-layer {
  margin-top: 5.5rem;

  @include media-breakpoint-up(lg) {
    margin-top: 9.0625rem;
  }
}

// open source
.home-open-source {
  margin: 4.75rem 0 4.1875rem 0;
}
